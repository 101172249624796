/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useMemo, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import ChatIcon from '@material-ui/icons/Chat';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Input, Checkbox, SingleSelect, Button } from '../../../../../_core/_components';

import * as S from './styles';
import { GuiaPagamentoMask, FASE, FASES_MAP, STATUS_OPTIONS } from '../helpers';
import { AjuizamentoDetalhes } from '../../types';
import { mascaraDDMMAAAA, mascaraNumeracaoPtBr } from '../../../../../_core/masks';
import api from '../../../../../_core/api';
import { failure, success } from '../../../../../_core/services/toast';

type Props = {
  ajuizamento: AjuizamentoDetalhes;
  onUpdateGuias: (guias: GuiaPagamentoMask[]) => void;
  onSave: () => void;
  guiasPagamento: GuiaPagamentoMask[];
  onRequestUpdateAjuizamento: (ajuizamento: AjuizamentoDetalhes) => void;
};

const StatusProcesso: React.FC<Props> = ({
  ajuizamento,
  onUpdateGuias,
  onSave,
  guiasPagamento,
  onRequestUpdateAjuizamento,
}) => {
  const [isLoading, setLoading] = useState(false);

  const classes = S.useStyles();

  const updateGuiasPagamento = (i: number, field: string, value: any) => {
    const updatedGuiasPagamento = [...guiasPagamento];
    updatedGuiasPagamento[i] = {
      ...updatedGuiasPagamento[i],
      [field]: value,
    };
    onUpdateGuias(updatedGuiasPagamento);
  };

  const verificarFaseConcluida = (nomeFase: FASE) => {
    const idFase = FASES_MAP.get(nomeFase)!;
    const fase = ajuizamento.historicoFases.find(e => e.fase.id === idFase);
    if (fase) {
      return fase.conclusao.length > 0;
    }
    return false;
  };

  const checkFilledCompPG = () => {
    return ajuizamento.guias.find(g => {
      return g.comprovantePagamento;
    });
  };

  const isAbrirChamadoVisible = useMemo(() => {
    return checkFilledCompPG();
  }, [ajuizamento]);

  const abrirChamado = async () => {
    try {
      setLoading(true);
      await api.post(`/workflow/ajuizamento/chamado/acoes/${ajuizamento.idAcaoAjuizamento}/abrir-chamado`);
      success('Chamado aberto com sucesso');
    } catch (e) {
      failure('Erro ao tentar abrir chamado, entre em contato com a equipe técnica');
    }
    onRequestUpdateAjuizamento(ajuizamento);
    setLoading(false);
  };

  return (
    <S.Wrapper>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <S.StyledTableCell>Guia pgto. (R$)</S.StyledTableCell>
            <S.StyledTableCell>Num. PO</S.StyledTableCell>
            <S.StyledTableCell>Num. Chamado pgto.</S.StyledTableCell>
            <S.StyledTableCell>Comp PG (R$)</S.StyledTableCell>
            <S.StyledTableCell>Nº. Chamado ajuizamento</S.StyledTableCell>
            <S.StyledTableCell>Status chamado ajuizamento</S.StyledTableCell>
            <S.StyledTableCell>Número protocolo</S.StyledTableCell>
            <S.StyledTableCell>Data ajuizamento</S.StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {guiasPagamento?.map((dado, idx) => (
            <React.Fragment key={idx}>
              <S.StyledTableRow key={idx}>
                <S.StyledTableCell component="th" scope="row">
                  <Input
                    pattern={mascaraNumeracaoPtBr}
                    state={[dado.valor, value => updateGuiasPagamento(idx, 'valor', value.toString())]}
                    disabled={verificarFaseConcluida('Guia de Pagamento')}
                  />
                </S.StyledTableCell>
                <S.StyledTableCell>
                  <Input
                    disabled={!dado.valor || verificarFaseConcluida('PO')}
                    state={[dado.numeroPO, value => updateGuiasPagamento(idx, 'numeroPO', value.toString())]}
                  />
                </S.StyledTableCell>

                <S.StyledTableCell>
                  <Input
                    disabled={!dado.valor || verificarFaseConcluida('Chamado para Pagamento')}
                    state={[
                      dado.numeroChamadoPagamento,
                      value => updateGuiasPagamento(idx, 'numeroChamadoPagamento', value.toString()),
                    ]}
                  />
                </S.StyledTableCell>
                <S.StyledTableCell>
                  <Checkbox
                    disabled={!dado.numeroChamadoPagamento || verificarFaseConcluida('Comprovantes de Pagamento')}
                    checked={dado.comprovantePagamento}
                    onClick={() =>
                      updateGuiasPagamento(idx, 'comprovantePagamento', !guiasPagamento[idx].comprovantePagamento)
                    }
                  />
                </S.StyledTableCell>
                <S.StyledTableCell>
                  <Input
                    disabled
                    state={[
                      dado.numeroChamadoAjuizamento,
                      value => updateGuiasPagamento(idx, 'numeroChamadoAjuizamento', value.toString()),
                    ]}
                  />
                </S.StyledTableCell>
                <S.StyledTableCell>
                  <Input
                    disabled
                    state={[
                      dado.statusChamadoAjuizamento,
                      option => updateGuiasPagamento(idx, 'statusChamadoAjuizamento', option),
                    ]}
                  />
                </S.StyledTableCell>
                <S.StyledTableCell>
                  <Input
                    disabled
                    state={[dado.numeroProtocolo, value => updateGuiasPagamento(idx, 'numeroProtocolo', value)]}
                  />
                </S.StyledTableCell>
                <S.StyledTableCell>
                  <Input
                    pattern={mascaraDDMMAAAA}
                    placeholder="DD/MM/YYYY"
                    state={[
                      dado.dataAjuizamento,
                      value => updateGuiasPagamento(idx, 'dataAjuizamento', value.toString()),
                    ]}
                    disabled
                  />
                </S.StyledTableCell>
              </S.StyledTableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <S.ActionsWrapper>
        {isAbrirChamadoVisible && (
          <Button
            variant="outlined"
            onClick={() => abrirChamado()}
            startIcon={isLoading ? <CircularProgress /> : <ChatIcon />}
            text="Abrir Chamado"
            disabled={guiasPagamento.every(e => e.numeroChamadoAjuizamento?.length > 0)}
          />
        )}

        <Button onClick={() => onSave()} startIcon={<SaveIcon />} text="Salvar" />
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};

export default StatusProcesso;
